// Auth
const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const HANDLE_VERIFY_DATA = 'HANDLE_VERIFY_DATA'

// User data
const HANDLE_AUTH_ERRORS = 'HANDLE_AUTH_ERRORS'
const HANDLE_VERIFICATION = 'HANDLE_VERIFICATION'
const HANDLE_NEW_USER_DATA = 'HANDLE_NEW_USER_DATA'

export const AUTH_TYPES = {
  // Auth
  LOGIN,
  LOGOUT,
  // User data
  HANDLE_AUTH_ERRORS,
  HANDLE_VERIFICATION,
  HANDLE_NEW_USER_DATA,
  HANDLE_VERIFY_DATA
}
