// Loader
const HANDLE_OPTIONS_IS_LOADING = 'HANDLE_OPTIONS_IS_LOADING'

// Timezones
const HANDLE_TIMEZONES_SUCCESS = 'HANDLE_TIMEZONES_SUCCESS'
const HANDLE_TIMEZONES_ERROR = 'HANDLE_TIMEZONES_ERROR'

// Degree
const HANDLE_DEGREE_SUCCESS = 'HANDLE_DEGREE_SUCCESS'
const HANDLE_DEGREE_ERROR = 'HANDLE_DEGREE_ERROR'

// Specialities
const HANDLE_SPECIALITIES_SUCCESS = 'HANDLE_SPECIALITIES_SUCCESS'
const HANDLE_SPECIALITIES_ERROR = 'HANDLE_SPECIALITIES_ERROR'

// States
const HANDLE_STATES_SUCCESS = 'HANDLE_STATES_SUCCESS'
const HANDLE_STATES_ERROR = 'HANDLE_STATES_ERROR'

// Roles
const HANDLE_ROLES_SUCCESS = 'HANDLE_ROLES_SUCCESS'
const HANDLE_ROLES_ERROR = 'HANDLE_ROLES_ERROR'

// Time
const HANDLE_TIME_SUCCESS = 'HANDLE_TIME_SUCCESS'
const HANDLE_TIME_ERROR = 'HANDLE_TIME_ERROR'

// Vacations
const HANDLE_VACATION_SUCCESS = 'HANDLE_VACATION_SUCCESS'
const HANDLE_VACATION_ERROR = 'HANDLE_VACATION_ERROR'

export const OPTIONS_TYPES = {
  HANDLE_OPTIONS_IS_LOADING,
  HANDLE_TIMEZONES_SUCCESS,
  HANDLE_TIMEZONES_ERROR,
  HANDLE_DEGREE_SUCCESS,
  HANDLE_DEGREE_ERROR,
  HANDLE_SPECIALITIES_SUCCESS,
  HANDLE_SPECIALITIES_ERROR,
  HANDLE_STATES_SUCCESS,
  HANDLE_STATES_ERROR,
  HANDLE_ROLES_SUCCESS,
  HANDLE_ROLES_ERROR,
  HANDLE_TIME_SUCCESS,
  HANDLE_TIME_ERROR,
  HANDLE_VACATION_SUCCESS,
  HANDLE_VACATION_ERROR
}
