// Auth
const USER_LOGIN = 'USER_LOGIN'
const USER_LOGOUT = 'USER_LOGOUT'
const AUTH_ERRORS = 'AUTH_ERRORS'

export const USER_TYPES = {
  USER_LOGIN,
  USER_LOGOUT,
  AUTH_ERRORS
}
