import { TEAM_MEMBER_TYPES } from '../../types/teamMember'
import { AUTH_TYPES } from '../../types/auth'

const initialState = {
  practice: {
    data: null,
    error: null
  },
  team: {
    data: null,
    error: null
  },
  activePatients: {
    data: null,
    error: null
  },
  appointments: {
    activeTab: 0,
    status: {
      unfulfilled: {
        data: null,
        error: null
      },
      fulfilled: {
        data: null,
        error: null
      },
      all: {
        data: null,
        error: null
      }
    }
  },
  vacations: {
    data: null,
    error: null
  },
  isLoading: false,
  isAppointmentsLoading: false,
  isVacationsLoading: false
}

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_MEMBER_TYPES.TEAM_MEMBER_IS_LOADING:
      return { ...state, isLoading: true }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_IS_LOADING:
      return { ...state, isAppointmentsLoading: true }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_PRACTICE_SUCCESS:
      return {
        ...state,
        practice: { data: action.practice, error: null },
        isLoading: false
      }
    case TEAM_MEMBER_TYPES.TEAM_MEMBER_PRACTICE_ERROR:
      return {
        ...state,
        practice: { ...state.practice, error: action.error },
        isLoading: false
      }

    case TEAM_MEMBER_TYPES.ACTIVE_PATIENTS_SUCCESS:
      return {
        ...state,
        activePatients: { data: action.payload, error: null },
        isLoading: false
      }
    case TEAM_MEMBER_TYPES.ACTIVE_PATIENTS_ERROR:
      return {
        ...state,
        activePatients: { ...state.activePatients, error: action.payload },
        isLoading: false
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_TEAM_SUCCESS:
      return {
        ...state,
        team: { data: action.team, error: null },
        isLoading: false
      }
    case TEAM_MEMBER_TYPES.TEAM_MEMBER_TEAM_ERROR:
      return {
        ...state,
        team: { ...state.team, error: action.error },
        isLoading: false
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_PASSWORD_SUCCESS:
      return { ...state, isLoading: false }
    case TEAM_MEMBER_TYPES.TEAM_MEMBER_PASSWORD_ERROR:
      return { ...state, isLoading: false }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_TAB:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          activeTab: action.tab
        }
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_SUCCESS:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            unfulfilled: { data: action.unfulfilled, error: null }
          }
        },
        isAppointmentsLoading: false
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_ERROR:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            unfulfilled: {
              ...state.appointments.status.unfulfilled,
              error: action.error
            }
          },
          isAppointmentsLoading: false
        }
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_FULFILLED_SUCCESS:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            fulfilled: { data: action.fulfilled, error: null }
          }
        },
        isAppointmentsLoading: false
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_FULFILLED_ERROR:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            fulfilled: {
              ...state.appointments.status.fulfilled,
              error: action.error
            }
          },
          isAppointmentsLoading: false
        }
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_ALL_SUCCESS:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            all: { data: action.all, error: null }
          }
        },
        isAppointmentsLoading: false
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_APPOINTMENTS_ALL_ERROR:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            all: {
              ...state.appointments.status.all,
              error: action.error
            }
          },
          isAppointmentsLoading: false
        }
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_VACATIONS_SUCCESS:
      return {
        ...state,
        vacations: {
          data: action.vacations,
          error: null
        },
        isVacationsLoading: false
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_VACATIONS_ERROR:
      return {
        ...state,
        vacations: {
          ...state.vacations,
          error: action.error
        },
        isVacationsLoading: false
      }

    case TEAM_MEMBER_TYPES.TEAM_MEMBER_VACATIONS_IS_LOADING:
      return { ...state, isVacationsLoading: true }

    case AUTH_TYPES.LOGOUT:
      return initialState

    default:
      return state
  }
}

export default doctorReducer
