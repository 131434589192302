import { DOCTOR_TYPES } from '../../types/doctor'
import { AUTH_TYPES } from '../../types/auth'

const initialState = {
  dashboard: {
    data: null,
    error: null
  },
  background: {
    data: null,
    error: null
  },
  payout: {
    data: null,
    error: null
  },
  practice: {
    data: null,
    error: null
  },
  team: {
    data: null,
    error: null
  },
  plans: {
    data: null,
    error: null
  },
  activePlans: {
    data: null,
    error: null
  },
  messages: {
    data: null,
    error: null
  },
  subscribers: {
    activeTab: 0,
    status: {
      pending: {
        data: null,
        error: null
      },
      active: {
        data: null,
        error: null
      },
      rejected: {
        data: null,
        error: null
      },
      expiredCancelled: {
        data: null,
        error: null
      },
      all: {
        data: null,
        error: null
      },
      approved: {
        data: null,
        error: null
      },
      expiring: {
        data: null,
        error: null
      }
    }
  },
  appointments: {
    activeTab: 0,
    status: {
      unfulfilled: {
        data: null,
        error: null
      },
      fulfilled: {
        data: null,
        error: null
      },
      all: {
        data: null,
        error: null
      }
    }
  },
  payments: {
    activeTab: 0,
    status: {
      charges: {
        data: null,
        error: null
      },
      refunds: {
        data: null,
        error: null
      },
      payouts: {
        data: null,
        error: null
      }
    }
  },
  vacations: {
    data: null,
    error: null
  },
  isLoading: false,
  isPatientsLoading: false,
  isAppointmentsLoading: false,
  isVacationsLoading: false,
  isDashboardLoading: false,
  isPaymentsLoading: false
}

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCTOR_TYPES.DOCTOR_PROFILE_IS_LOADING:
      return { ...state, isLoading: true }

    case DOCTOR_TYPES.DOCTOR_DASHBOARD_IS_LOADING:
      return { ...state, isDashboardLoading: true }

    case DOCTOR_TYPES.DOCTOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: { data: action.dashboard, error: null },
        isDashboardLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_DASHBOARD_ERROR:
      return {
        ...state,
        dashboard: { ...state.dashboard, error: action.error },
        isDashboardLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_BACKGROUND_SUCCESS:
      return {
        ...state,
        background: { data: action.background, error: null },
        isLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_BACKGROUND_ERROR:
      return {
        ...state,
        background: { ...state.background, error: action.error },
        isLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PAYOUT_SUCCESS:
      return {
        ...state,
        payout: { data: action.payout, error: null },
        isLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_PAYOUT_ERROR:
      return {
        ...state,
        payout: { ...state.payout, error: action.error },
        isLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PRACTICE_SUCCESS:
      return {
        ...state,
        practice: { data: action.practice, error: null },
        isLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_PRACTICE_ERROR:
      return {
        ...state,
        practice: { ...state.practice, error: action.error },
        isLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_TEAM_SUCCESS:
      return {
        ...state,
        team: { data: action.team, error: null },
        isLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_TEAM_ERROR:
      return {
        ...state,
        team: { ...state.team, error: action.error },
        isLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PLANS_SUCCESS:
      return {
        ...state,
        plans: { data: action.plans, error: null },
        isLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_PLANS_ERROR:
      return {
        ...state,
        plans: { ...state.plans, error: action.error },
        isLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_ACTIVE_PLANS_SUCCESS:
      return {
        ...state,
        activePlans: { data: action.data, error: null },
        isLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_ACTIVE_PLANS_ERROR:
      return {
        ...state,
        activePlans: { ...state.activePlans, error: action.error },
        isLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: { data: action.data, error: null },
        isLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_MESSAGES_ERROR:
      return {
        ...state,
        messages: { ...state.messages, error: action.error },
        isLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PATIENTS_IS_LOADING:
      return { ...state, isPatientsLoading: true }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_IS_LOADING:
      return { ...state, isAppointmentsLoading: true }

    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_ALL_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            all: { data: action.all, error: null }
          }
        },
        isPatientsLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_ALL_ERROR:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            all: { ...state.subscribers.status.all, error: action.error }
          }
        },
        isPatientsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_PENDING_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            pending: { data: action.pending, error: null }
          }
        },
        isPatientsLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_PENDING_ERROR:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            pending: {
              ...state.subscribers.status.pending,
              error: action.error
            }
          }
        },
        isPatientsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_APPROVED_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            approved: { data: action.approved, error: null }
          }
        },
        isPatientsLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_APPROVED_ERROR:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            approved: {
              ...state.subscribers.status.approved,
              error: action.error
            }
          }
        },
        isPatientsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_EXPIRING_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            expiring: { data: action.expiring, error: null }
          }
        },
        isPatientsLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_EXPIRING_ERROR:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            expiring: {
              ...state.subscribers.status.expiring,
              error: action.error
            }
          }
        },
        isPatientsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_ACTIVE_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            active: { data: action.active, error: null }
          }
        },
        isPatientsLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_ACTIVE_ERROR:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            active: {
              ...state.subscribers.status.active,
              error: action.error
            }
          }
        },
        isPatientsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_REJECTED_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            rejected: { data: action.rejected, error: null }
          }
        },
        isPatientsLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_REJECTED_ERROR:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            rejected: {
              ...state.subscribers.status.rejected,
              error: action.error
            }
          }
        },
        isPatientsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_SUCCESS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            expiredCancelled: { data: action.expiredCancelled, error: null }
          }
        },
        isPatientsLoading: false
      }
    case DOCTOR_TYPES.DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_ERROR:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          status: {
            ...state.subscribers.status,
            expiredCancelled: {
              ...state.subscribers.status.expiredCancelled,
              error: action.error
            }
          }
        },
        isPatientsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PATIENTS_TAB:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          activeTab: action.tab
        }
      }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_TAB:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          activeTab: action.tab
        }
      }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_UNFULFILLED_SUCCESS:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            unfulfilled: { data: action.unfulfilled, error: null }
          }
        },
        isAppointmentsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_UNFULFILLED_ERROR:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            unfulfilled: {
              ...state.appointments.status.unfulfilled,
              error: action.error
            }
          },
          isAppointmentsLoading: false
        }
      }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_FULFILLED_SUCCESS:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            fulfilled: { data: action.fulfilled, error: null }
          }
        },
        isAppointmentsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_FULFILLED_ERROR:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            fulfilled: {
              ...state.appointments.status.fulfilled,
              error: action.error
            }
          },
          isAppointmentsLoading: false
        }
      }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_ALL_SUCCESS:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            all: { data: action.all, error: null }
          }
        },
        isAppointmentsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_APPOINTMENTS_ALL_ERROR:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          status: {
            ...state.appointments.status,
            all: {
              ...state.appointments.status.all,
              error: action.error
            }
          },
          isAppointmentsLoading: false
        }
      }

    case DOCTOR_TYPES.DOCTOR_VACATIONS_SUCCESS:
      return {
        ...state,
        vacations: {
          data: action.vacations,
          error: null
        },
        isVacationsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_VACATIONS_ERROR:
      return {
        ...state,
        vacations: {
          ...state.vacations,
          error: action.error
        },
        isVacationsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_VACATIONS_IS_LOADING:
      return { ...state, isVacationsLoading: true }

    // [START]Payments
    case DOCTOR_TYPES.DOCTOR_PAYMENTS_IS_LOADING:
      return { ...state, isPaymentsLoading: true }

    case DOCTOR_TYPES.DOCTOR_PAYMENTS_TAB:
      return {
        ...state,
        payments: {
          ...state.payments,
          activeTab: action.tab
        }
      }

    case DOCTOR_TYPES.DOCTOR_PAYMENTS_CHARGES_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          status: {
            ...state.payments.status,
            charges: { data: action.charges, error: null }
          }
        },
        isPaymentsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PAYMENTS_CHARGES_ERROR:
      return {
        ...state,
        payments: {
          ...state.payments,
          status: {
            ...state.payments.status,
            charges: {
              ...state.payments.status.charges,
              error: action.error
            }
          },
          isPaymentsLoading: false
        }
      }

    case DOCTOR_TYPES.DOCTOR_PAYMENTS_REFUNDS_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          status: {
            ...state.payments.status,
            refunds: { data: action.refunds, error: null }
          }
        },
        isPaymentsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PAYMENTS_REFUNDS_ERROR:
      return {
        ...state,
        payments: {
          ...state.payments,
          status: {
            ...state.payments.status,
            refunds: {
              ...state.payments.status.refunds,
              error: action.error
            }
          },
          isPaymentsLoading: false
        }
      }

    case DOCTOR_TYPES.DOCTOR_PAYMENTS_PAYOUTS_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          status: {
            ...state.payments.status,
            payouts: { data: action.payouts, error: null }
          }
        },
        isPaymentsLoading: false
      }

    case DOCTOR_TYPES.DOCTOR_PAYMENTS_PAYOUTS_ERROR:
      return {
        ...state,
        payments: {
          ...state.payments,
          status: {
            ...state.payments.status,
            payouts: {
              ...state.payments.status.payouts,
              error: action.error
            }
          },
          isPaymentsLoading: false
        }
      }
    // [END]Payments

    case AUTH_TYPES.LOGOUT:
      return initialState

    default:
      return state
  }
}

export default doctorReducer
