// Loader
const PATIENT_CARDS_IS_LOADING = 'PATIENT_CARDS_IS_LOADING'
const PATIENT_BILLING_IS_LOADING = 'PATIENT_BILLING_IS_LOADING'
const PATIENT_INVOICES_IS_LOADING = 'PATIENT_INVOICES_IS_LOADING'
const PATIENT_SUBSCRIPTIONS_IS_LOADING = 'PATIENT_SUBSCRIPTIONS_IS_LOADING'
const PATIENT_APPOINTMENTS_IS_LOADING = 'PATIENT_APPOINTMENTS_IS_LOADING'

// Trigger
const PATIENT_APPOINTMENTS_TRIGGER = 'PATIENT_APPOINTMENTS_TRIGGER'

// Cards [payments]
const PATIENT_CARDS_SUCCESS = 'PATIENT_CARDS_SUCCESS'
const PATIENT_CARDS_ERROR = 'PATIENT_CARDS_ERROR'

// Billing
const PATIENT_BILLING_SUCCESS = 'PATIENT_BILLING_SUCCESS'
const PATIENT_BILLING_ERROR = 'PATIENT_BILLING_ERROR'

// Invoices
const PATIENT_INVOICES_SUCCESS = 'PATIENT_INVOICES_SUCCESS'
const PATIENT_INVOICES_ERROR = 'PATIENT_INVOICES_ERROR'

// Subscriptions
const PATIENT_SUBSCRIPTIONS_SUCCESS = 'PATIENT_SUBSCRIPTIONS_SUCCESS'
const PATIENT_SUBSCRIPTIONS_ERROR = 'PATIENT_SUBSCRIPTIONS_ERROR'

// Appointments
const PATIENT_APPOINTMENTS_SUCCESS = 'PATIENT_APPOINTMENTS_SUCCESS'
const PATIENT_APPOINTMENTS_ERROR = 'PATIENT_APPOINTMENTS_ERROR'

// API KEY
const API_KEY_SUCCESS = 'API_KEY_SUCCESS'
const API_KEY_ERROR = 'API_KEY_ERROR'

export const PATIENT_TYPES = {
  // Cards
  PATIENT_CARDS_IS_LOADING,
  PATIENT_CARDS_SUCCESS,
  PATIENT_CARDS_ERROR,
  // Billing
  PATIENT_BILLING_IS_LOADING,
  PATIENT_BILLING_SUCCESS,
  PATIENT_BILLING_ERROR,
  // Invoices
  PATIENT_INVOICES_IS_LOADING,
  PATIENT_INVOICES_SUCCESS,
  PATIENT_INVOICES_ERROR,
  // Subscriptions
  PATIENT_SUBSCRIPTIONS_IS_LOADING,
  PATIENT_SUBSCRIPTIONS_SUCCESS,
  PATIENT_SUBSCRIPTIONS_ERROR,
  // Appointments
  PATIENT_APPOINTMENTS_IS_LOADING,
  PATIENT_APPOINTMENTS_SUCCESS,
  PATIENT_APPOINTMENTS_ERROR,
  PATIENT_APPOINTMENTS_TRIGGER,
  // Stripe
  API_KEY_SUCCESS,
  API_KEY_ERROR
}
