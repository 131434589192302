import { USER_TYPES } from '../../types/clearAuth'

const initialState = {
  user: null,
  errors: null
}

const clearAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.USER_LOGIN:
      return { user: action.user, errors: null }
    case USER_TYPES.USER_LOGOUT:
      return { user: null, errors: null }
    case USER_TYPES.AUTH_ERRORS:
      return { user: null, errors: action.errors }
    default:
      return state
  }
}

export default clearAuthReducer
