import { AUTH_TYPES } from '../../types/auth'

// **  Initial State
const initialState = {
  userData: {},
  errors: null,
  verificationData: null,
  newUserData: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN:
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName],
        errors: null
      }
    case AUTH_TYPES.LOGOUT:
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, errors: null, ...obj }
    case AUTH_TYPES.HANDLE_AUTH_ERRORS:
      return { ...state, errors: action.errors }
    case AUTH_TYPES.HANDLE_VERIFICATION:
      return { ...state, verificationData: action.verificationData }
    case AUTH_TYPES.HANDLE_NEW_USER_DATA:
      return { ...state, newUserData: action.newUserData }

    default:
      return state
  }
}

export default authReducer
