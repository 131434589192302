// Loader
const TEAM_MEMBER_IS_LOADING = 'TEAM_MEMBER_IS_LOADING'
const TEAM_MEMBER_APPOINTMENTS_IS_LOADING =
  'TEAM_MEMBER_APPOINTMENTS_IS_LOADING'
const TEAM_MEMBER_VACATIONS_IS_LOADING = 'TEAM_MEMBER_VACATIONS_IS_LOADING'

// Team
const TEAM_MEMBER_TEAM_SUCCESS = 'TEAM_MEMBER_TEAM_SUCCESS'
const TEAM_MEMBER_TEAM_ERROR = 'TEAM_MEMBER_TEAM_ERROR'

// Practice
const TEAM_MEMBER_PRACTICE_SUCCESS = 'TEAM_MEMBER_PRACTICE_SUCCESS'
const TEAM_MEMBER_PRACTICE_ERROR = 'TEAM_MEMBER_PRACTICE_ERROR'

// Appointments
// - Unfulfilled
const TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_SUCCESS =
  'TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_SUCCESS'
const TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_ERROR =
  'TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_ERROR'
// - Fulfilled
const TEAM_MEMBER_APPOINTMENTS_FULFILLED_SUCCESS =
  'TEAM_MEMBER_APPOINTMENTS_FULFILLED_SUCCESS'
const TEAM_MEMBER_APPOINTMENTS_FULFILLED_ERROR =
  'TEAM_MEMBER_APPOINTMENTS_FULFILLED_ERROR'
// - All
const TEAM_MEMBER_APPOINTMENTS_ALL_SUCCESS =
  'TEAM_MEMBER_APPOINTMENTS_ALL_SUCCESS'
const TEAM_MEMBER_APPOINTMENTS_ALL_ERROR = 'TEAM_MEMBER_APPOINTMENTS_ALL_ERROR'

// Appointment tab
const TEAM_MEMBER_APPOINTMENTS_TAB = 'TEAM_MEMBER_APPOINTMENTS_TAB'

// Password
const TEAM_MEMBER_PASSWORD_SUCCESS = 'TEAM_MEMBER_PASSWORD_SUCCESS'
const TEAM_MEMBER_PASSWORD_ERROR = 'TEAM_MEMBER_PASSWORD_ERROR'

// Vacations
const TEAM_MEMBER_VACATIONS_SUCCESS = 'TEAM_MEMBER_VACATIONS_SUCCESS'
const TEAM_MEMBER_VACATIONS_ERROR = 'TEAM_MEMBER_VACATIONS_ERROR'

// Active patients
const ACTIVE_PATIENTS_SUCCESS = 'ACTIVE_PATIENTS_SUCCESS'
const ACTIVE_PATIENTS_ERROR = 'ACTIVE_PATIENTS_ERROR'

export const TEAM_MEMBER_TYPES = {
  // Loader
  TEAM_MEMBER_IS_LOADING,
  TEAM_MEMBER_APPOINTMENTS_IS_LOADING,
  TEAM_MEMBER_VACATIONS_IS_LOADING,
  // Team
  TEAM_MEMBER_TEAM_SUCCESS,
  TEAM_MEMBER_TEAM_ERROR,
  // Practice
  TEAM_MEMBER_PRACTICE_SUCCESS,
  TEAM_MEMBER_PRACTICE_ERROR,
  // Appointments
  // - Unfulfilled
  TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_SUCCESS,
  TEAM_MEMBER_APPOINTMENTS_UNFULFILLED_ERROR,
  // - Fulfilled
  TEAM_MEMBER_APPOINTMENTS_FULFILLED_SUCCESS,
  TEAM_MEMBER_APPOINTMENTS_FULFILLED_ERROR,
  // - All
  TEAM_MEMBER_APPOINTMENTS_ALL_SUCCESS,
  TEAM_MEMBER_APPOINTMENTS_ALL_ERROR,
  // Appointment tab
  TEAM_MEMBER_APPOINTMENTS_TAB,
  // Password
  TEAM_MEMBER_PASSWORD_SUCCESS,
  TEAM_MEMBER_PASSWORD_ERROR,
  // Vacations
  TEAM_MEMBER_VACATIONS_SUCCESS,
  TEAM_MEMBER_VACATIONS_ERROR,
  // Active patients
  ACTIVE_PATIENTS_SUCCESS,
  ACTIVE_PATIENTS_ERROR
}
