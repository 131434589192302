const doctor = [
  {
    key: 'mainMenuKey1',
    label: 'Dashboard',
    link: '/doctor/dashboard'
  },
  {
    key: 'mainMenuKey2',
    label: 'Plans',
    link: '/doctor/plans'
  },
  {
    key: 'mainMenuKey3',
    label: 'Patients',
    link: '/doctor/patients',
    info: 0
  },
  {
    key: 'mainMenuKey4',
    label: 'Appointments',
    link: '/doctor/appointments',
    info: 0
  },
  {
    key: 'mainMenuKey8',
    label: 'Messages',
    link: '/doctor/messages',
    info: 0
  },
  {
    key: 'mainMenuKey5',
    label: 'Team',
    link: '/doctor/team'
  },
  {
    key: 'mainMenuKey6',
    label: 'Vacations',
    link: '/doctor/vacations'
  },
  {
    key: 'mainMenuKey7',
    label: 'Payments',
    link: '/doctor/payments'
  }
]

const teamMember = [
  {
    key: 'mainMenuKey1',
    label: 'Appointment Requests',
    link: '/team-member/appointment-requests',
    info: 0
  },
  {
    key: 'mainMenuKey2',
    label: 'Vacations',
    link: '/team-member/vacations'
  },
  {
    key: 'mainMenuKey3',
    label: 'Active Patients',
    link: '/team-member/active-patients'
  }
]

const admin = null

const defaultMenu = []
//   {
//     key: 'mainMenuKey1',
//     label: 'Home',
//     link: '/home'
//   },
//   {
//     key: 'mainMenuKey2',
//     label: 'Doctors',
//     link: '/doctors'
//   },
//   {
//     key: 'mainMenuKey3',
//     label: 'Features',
//     link: '/features'
//   },
//   {
//     key: 'mainMenuKey4',
//     label: 'About Us',
//     link: '/about-us'
//   }
// ]

const patientProfile = defaultMenu

const doctorDropdown = [
  {
    key: 'dropdownKey1',
    label: 'Profile',
    link: '/doctor/profile',
    icon: 'User'
  }
]

const teamMemberDropdown = [
  {
    key: 'dropdownKey1',
    label: 'Profile',
    link: '/team-member/profile',
    icon: 'User'
  }
]

const adminDropdown = [
  {
    key: 'dropdownKey1',
    label: 'Profile',
    link: '/admin/profile',
    icon: 'User'
  }
]

const patientDropdown = [
  {
    key: 'dropdownKey1',
    label: 'Profile',
    link: '/patient/profile',
    icon: 'User'
  },
  {
    key: 'dropdownKey2',
    label: 'Invoices',
    link: '/patient/invoices',
    icon: 'Flag'
  }
  // {
  //   key: 'mainMenuKey2',
  //   label: 'Doctors',
  //   link: '/doctors',
  //   icon: 'User'
  // }
]

const defaultDropdown = null

export const dropdownMenu = [
  defaultDropdown,
  adminDropdown,
  doctorDropdown,
  patientDropdown,
  teamMemberDropdown
]

export const menu = [defaultMenu, admin, doctor, patientProfile, teamMember]

export const defaultRoute = [
  '/',
  '/admin/doctors',
  '/doctor/dashboard',
  '/patient/subscriptions',
  '/team-member/active-patients'
]
