import { ADMIN_TYPES } from '../../types/admin'
import { AUTH_TYPES } from '../../types/auth'

const initialState = {
  menuBadges: null
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_TYPES.MENU_BADGES_SUCCESS: {
      return { ...state, menuBadges: { data: action.payload, error: null } }
    }
    case ADMIN_TYPES.MENU_BADGES_SUCCESS: {
      return { ...state, menuBadges: { data: null, error: action.payload } }
    }
    case AUTH_TYPES.LOGOUT:
      return initialState

    default:
      return state
  }
}

export default adminReducer
