import { NAVBAR_TYPES } from '../../types/navbar'
import { AUTH_TYPES } from '../../types/auth'
import { menu, dropdownMenu, defaultRoute } from './menu'

const initialState = {
  suggestions: [],
  bookmarks: [],
  query: '',
  activeItemKey: 'mainMenuKey1',
  menuType: 'default',
  defaultMenu: menu[0],
  defaultRoute: defaultRoute[0],
  menu: menu[0],
  dropdownMenu: dropdownMenu[0],
  isPatientProfile: false
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVBAR_TYPES.HANDLE_SEARCH_QUERY:
      return { ...state, query: action.val }
    case NAVBAR_TYPES.GET_BOOKMARKS:
      return { ...state, suggestions: action.data, bookmarks: action.bookmarks }
    case NAVBAR_TYPES.UPDATE_BOOKMARKED:
      return { ...state }
    case NAVBAR_TYPES.CHANGE_ACTIVE_MENU_ITEM:
      return { ...state, activeItemKey: action.newActiveMenuItem }
    case NAVBAR_TYPES.CHANGE_MENU_TYPE:
      return {
        ...state,
        menuType: action.newMenuType,
        menu: menu[action.newMenuTypeID],
        dropdownMenu: dropdownMenu[action.newMenuTypeID],
        defaultRoute: defaultRoute[action.newMenuTypeID]
      }
    case AUTH_TYPES.LOGOUT:
      return initialState
    default:
      return state
  }
}

export default navbarReducer
