// Loader
const IS_USER_DATA_LOADING = 'IS_USER_DATA_LOADING'

// User data
const HANDLE_USER_DATA_SUCCESS = 'HANDLE_USER_DATA_SUCCESS'
const HANDLE_USER_DATA_ERROR = 'HANDLE_USER_DATA_ERROR'

export const USER_TYPES = {
  // Loader
  IS_USER_DATA_LOADING,
  // User data
  HANDLE_USER_DATA_SUCCESS,
  HANDLE_USER_DATA_ERROR
}
