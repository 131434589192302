// Menu
const CHANGE_ACTIVE_MENU_ITEM = 'HANDLE_ACTIVE_ITEM'
const HANDLE_SEARCH_QUERY = 'HANDLE_SEARCH_QUERY'
const GET_BOOKMARKS = 'GET_BOOKMARKS'
const UPDATE_BOOKMARKED = 'UPDATE_BOOKMARKED'
const CHANGE_MENU_TYPE = 'CHANGE_MENU_TYPE'

export const NAVBAR_TYPES = {
  CHANGE_ACTIVE_MENU_ITEM,
  CHANGE_MENU_TYPE,
  HANDLE_SEARCH_QUERY,
  GET_BOOKMARKS,
  UPDATE_BOOKMARKED
}
