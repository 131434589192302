import { OPTIONS_TYPES } from '../../types/options'
import { AUTH_TYPES } from '../../types/auth'

const initialState = {
  timezones: {
    data: null,
    error: null
  },
  degree: {
    data: null,
    error: null
  },
  specialities: {
    data: null,
    error: null
  },
  states: {
    data: null,
    error: null
  },
  roles: {
    data: null,
    error: null
  },
  vacation: {
    data: null,
    error: null
  },
  time: {},
  isLoading: false
}

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPTIONS_TYPES.HANDLE_OPTIONS_IS_LOADING:
      return { ...state, isLoading: true }

    case OPTIONS_TYPES.HANDLE_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: { data: action.timezones, error: null },
        isLoading: false
      }
    case OPTIONS_TYPES.HANDLE_TIMEZONES_ERROR:
      return {
        ...state,
        timezones: { data: null, error: action.error },
        isLoading: false
      }

    case OPTIONS_TYPES.HANDLE_SPECIALITIES_SUCCESS:
      return {
        ...state,
        specialities: { data: action.specialities, error: null },
        isLoading: false
      }
    case OPTIONS_TYPES.HANDLE_SPECIALITIES_ERROR:
      return {
        ...state,
        specialities: { data: null, error: action.error },
        isLoading: false
      }

    case OPTIONS_TYPES.HANDLE_DEGREE_SUCCESS:
      return {
        ...state,
        degree: { data: action.degree, error: null },
        isLoading: false
      }
    case OPTIONS_TYPES.HANDLE_DEGREE_ERROR:
      return {
        ...state,
        degree: { data: null, error: action.error },
        isLoading: false
      }

    case OPTIONS_TYPES.HANDLE_STATES_SUCCESS:
      return {
        ...state,
        states: { data: action.states, error: null },
        isLoading: false
      }
    case OPTIONS_TYPES.HANDLE_STATES_ERROR:
      return {
        ...state,
        states: { data: null, error: action.error },
        isLoading: false
      }

    case OPTIONS_TYPES.HANDLE_ROLES_SUCCESS:
      return {
        ...state,
        roles: { data: action.roles, error: null },
        isLoading: false
      }
    case OPTIONS_TYPES.HANDLE_ROLES_ERROR:
      return {
        ...state,
        roles: { data: null, error: action.error },
        isLoading: false
      }

    case OPTIONS_TYPES.HANDLE_TIME_SUCCESS:
      return {
        ...state,
        time: { data: action.time, error: null },
        isLoading: false
      }
    case OPTIONS_TYPES.HANDLE_TIME_ERROR:
      return {
        ...state,
        time: { data: null, error: action.error },
        isLoading: false
      }

    case OPTIONS_TYPES.HANDLE_VACATION_SUCCESS:
      return {
        ...state,
        vacation: { data: action.vacation, error: null },
        isLoading: false
      }
    case OPTIONS_TYPES.HANDLE_VACATION_ERROR:
      return {
        ...state,
        vacation: { data: null, error: action.error },
        isLoading: false
      }

    case AUTH_TYPES.LOGOUT:
      return initialState

    default:
      return state
  }
}

export default doctorReducer
