// Loader
const DOCTOR_PROFILE_IS_LOADING = 'DOCTOR_PROFILE_IS_LOADING'
const DOCTOR_PATIENTS_IS_LOADING = 'DOCTOR_PATIENTS_IS_LOADING'
const DOCTOR_APPOINTMENTS_IS_LOADING = 'DOCTOR_APPOINTMENTS_IS_LOADING'
const DOCTOR_VACATIONS_IS_LOADING = 'DOCTOR_VACATIONS_IS_LOADING'
const DOCTOR_DASHBOARD_IS_LOADING = 'DOCTOR_DASHBOARD_IS_LOADING'
const DOCTOR_PAYMENTS_IS_LOADING = 'DOCTOR_PAYMENTS_IS_LOADING'

// Background
const DOCTOR_BACKGROUND_SUCCESS = 'DOCTOR_BACKGROUND_SUCCESS'
const DOCTOR_BACKGROUND_ERROR = 'DOCTOR_BACKGROUND_ERROR'

// Payout
const DOCTOR_PAYOUT_SUCCESS = 'DOCTOR_PAYOUT_SUCCESS'
const DOCTOR_PAYOUT_ERROR = 'DOCTOR_PAYOUT_ERROR'

// Practice
const DOCTOR_PRACTICE_SUCCESS = 'DOCTOR_PRACTICE_SUCCESS'
const DOCTOR_PRACTICE_ERROR = 'DOCTOR_PRACTICE_ERROR'

// Team
const DOCTOR_TEAM_SUCCESS = 'DOCTOR_TEAM_SUCCESS'
const DOCTOR_TEAM_ERROR = 'DOCTOR_TEAM_ERROR'

// Plans
const DOCTOR_PLANS_SUCCESS = 'DOCTOR_PLANS_SUCCESS'
const DOCTOR_PLANS_ERROR = 'DOCTOR_PLANS_ERROR'

// Active Plans
const DOCTOR_ACTIVE_PLANS_SUCCESS = 'DOCTOR_ACTIVE_PLANS_SUCCESS'
const DOCTOR_ACTIVE_PLANS_ERROR = 'DOCTOR_ACTIVE_PLANS_ERROR'

// Messages
const DOCTOR_MESSAGES_SUCCESS = 'DOCTOR_MESSAGES_SUCCESS'
const DOCTOR_MESSAGES_ERROR = 'DOCTOR_MESSAGES_ERROR'

// Subscribers
// - All
const DOCTOR_SUBSCRIBERS_ALL_SUCCESS = 'DOCTOR_SUBSCRIBERS_ALL_SUCCESS'
const DOCTOR_SUBSCRIBERS_ALL_ERROR = 'DOCTOR_SUBSCRIBERS_ALL_SUCCESS'
// - Pending
const DOCTOR_SUBSCRIBERS_PENDING_SUCCESS = 'DOCTOR_SUBSCRIBERS_PENDING_SUCCESS'
const DOCTOR_SUBSCRIBERS_PENDING_ERROR = 'DOCTOR_SUBSCRIBERS_PENDING_ERROR'
// - Rejected
const DOCTOR_SUBSCRIBERS_REJECTED_SUCCESS =
  'DOCTOR_SUBSCRIBERS_REJECTED_SUCCESS'
const DOCTOR_SUBSCRIBERS_REJECTED_ERROR = 'DOCTOR_SUBSCRIBERS_REJECTED_ERROR'
// - Expired or Cancelled
const DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_SUCCESS =
  'DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_SUCCESS'
const DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_ERROR =
  'DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_ERROR'
// - Active
const DOCTOR_SUBSCRIBERS_ACTIVE_SUCCESS = 'DOCTOR_SUBSCRIBERS_ACTIVE_SUCCESS'
const DOCTOR_SUBSCRIBERS_ACTIVE_ERROR = 'DOCTOR_SUBSCRIBERS_ACTIVE_ERROR'
// - Approved
const DOCTOR_SUBSCRIBERS_APPROVED_SUCCESS =
  'DOCTOR_SUBSCRIBERS_APPROVED_SUCCESS'
const DOCTOR_SUBSCRIBERS_APPROVED_ERROR = 'DOCTOR_SUBSCRIBERS_APPROVED_ERROR'
// - Expriring
const DOCTOR_SUBSCRIBERS_EXPIRING_SUCCESS =
  'DOCTOR_SUBSCRIBERS_EXPIRING_SUCCESS'
const DOCTOR_SUBSCRIBERS_EXPIRING_ERROR = 'DOCTOR_SUBSCRIBERS_EXPIRING_ERROR'

// Appointments
// - Unfulfilled
const DOCTOR_APPOINTMENTS_UNFULFILLED_SUCCESS =
  'DOCTOR_APPOINTMENTS_UNFULFILLED_SUCCESS'
const DOCTOR_APPOINTMENTS_UNFULFILLED_ERROR =
  'DOCTOR_APPOINTMENTS_UNFULFILLED_ERROR'
// - Fulfilled
const DOCTOR_APPOINTMENTS_FULFILLED_SUCCESS =
  'DOCTOR_APPOINTMENTS_FULFILLED_SUCCESS'
const DOCTOR_APPOINTMENTS_FULFILLED_ERROR =
  'DOCTOR_APPOINTMENTS_FULFILLED_ERROR'
// - All
const DOCTOR_APPOINTMENTS_ALL_SUCCESS = 'DOCTOR_APPOINTMENTS_ALL_SUCCESS'
const DOCTOR_APPOINTMENTS_ALL_ERROR = 'DOCTOR_APPOINTMENTS_ALL_ERROR'

// Vacations
const DOCTOR_VACATIONS_SUCCESS = 'DOCTOR_VACATIONS_SUCCESS'
const DOCTOR_VACATIONS_ERROR = 'DOCTOR_VACATIONS_ERROR'

// Dashboard
const DOCTOR_DASHBOARD_SUCCESS = 'DOCTOR_DASHBOARD_SUCCESS'
const DOCTOR_DASHBOARD_ERROR = 'DOCTOR_DASHBOARD_ERROR'

// Payments
// - Charges
const DOCTOR_PAYMENTS_CHARGES_SUCCESS = 'DOCTOR_PAYMENTS_CHARGES_SUCCESS'
const DOCTOR_PAYMENTS_CHARGES_ERROR = 'DOCTOR_PAYMENTS_CHARGES_ERROR'
// - Refunds
const DOCTOR_PAYMENTS_REFUNDS_SUCCESS = 'DOCTOR_PAYMENTS_REFUNDS_SUCCESS'
const DOCTOR_PAYMENTS_REFUNDS_ERROR = 'DOCTOR_PAYMENTS_REFUNDS_ERROR'
// - Payouts
const DOCTOR_PAYMENTS_PAYOUTS_SUCCESS = 'DOCTOR_PAYMENTS_PAYOUTS_SUCCESS'
const DOCTOR_PAYMENTS_PAYOUTS_ERROR = 'DOCTOR_PAYMENTS_PAYOUTS_ERROR'

// Tabs
const DOCTOR_PATIENTS_TAB = 'DOCTOR_PATIENTS_TAB'
const DOCTOR_APPOINTMENTS_TAB = 'DOCTOR_APPOINTMENTS_TAB'
const DOCTOR_PAYMENTS_TAB = 'DOCTOR_PAYMENTS_TAB'

export const DOCTOR_TYPES = {
  // Loader
  DOCTOR_PROFILE_IS_LOADING,
  DOCTOR_PATIENTS_IS_LOADING,
  DOCTOR_APPOINTMENTS_IS_LOADING,
  DOCTOR_VACATIONS_IS_LOADING,
  DOCTOR_DASHBOARD_IS_LOADING,
  DOCTOR_PAYMENTS_IS_LOADING,
  // Background
  DOCTOR_BACKGROUND_SUCCESS,
  DOCTOR_BACKGROUND_ERROR,
  // Payout
  DOCTOR_PAYOUT_SUCCESS,
  DOCTOR_PAYOUT_ERROR,
  // Practice
  DOCTOR_PRACTICE_SUCCESS,
  DOCTOR_PRACTICE_ERROR,
  // Team
  DOCTOR_TEAM_SUCCESS,
  DOCTOR_TEAM_ERROR,
  // Plans
  DOCTOR_PLANS_SUCCESS,
  DOCTOR_PLANS_ERROR,
  // Active Plans
  DOCTOR_ACTIVE_PLANS_SUCCESS,
  DOCTOR_ACTIVE_PLANS_ERROR,
  // Messages
  DOCTOR_MESSAGES_SUCCESS,
  DOCTOR_MESSAGES_ERROR,
  //  Subscribers
  // - All
  DOCTOR_SUBSCRIBERS_ALL_SUCCESS,
  DOCTOR_SUBSCRIBERS_ALL_ERROR,
  // - Pending
  DOCTOR_SUBSCRIBERS_PENDING_SUCCESS,
  DOCTOR_SUBSCRIBERS_PENDING_ERROR,
  // - Rejected
  DOCTOR_SUBSCRIBERS_REJECTED_SUCCESS,
  DOCTOR_SUBSCRIBERS_REJECTED_ERROR,
  // - Expired or Cancelled
  DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_SUCCESS,
  DOCTOR_SUBSCRIBERS_EXPIRED_CANCELLED_ERROR,
  // - Active
  DOCTOR_SUBSCRIBERS_ACTIVE_SUCCESS,
  DOCTOR_SUBSCRIBERS_ACTIVE_ERROR,
  // - Approved
  DOCTOR_SUBSCRIBERS_APPROVED_SUCCESS,
  DOCTOR_SUBSCRIBERS_APPROVED_ERROR,
  // - Expiring
  DOCTOR_SUBSCRIBERS_EXPIRING_SUCCESS,
  DOCTOR_SUBSCRIBERS_EXPIRING_ERROR,
  // Appointments
  // - Unfulfilled
  DOCTOR_APPOINTMENTS_UNFULFILLED_SUCCESS,
  DOCTOR_APPOINTMENTS_UNFULFILLED_ERROR,
  // - Fulfilled
  DOCTOR_APPOINTMENTS_FULFILLED_SUCCESS,
  DOCTOR_APPOINTMENTS_FULFILLED_ERROR,
  // - All
  DOCTOR_APPOINTMENTS_ALL_SUCCESS,
  DOCTOR_APPOINTMENTS_ALL_ERROR,
  // Vacations
  DOCTOR_VACATIONS_SUCCESS,
  DOCTOR_VACATIONS_ERROR,
  // Dashboard
  DOCTOR_DASHBOARD_SUCCESS,
  DOCTOR_DASHBOARD_ERROR,
  // Payments
  // - Charges
  DOCTOR_PAYMENTS_CHARGES_SUCCESS,
  DOCTOR_PAYMENTS_CHARGES_ERROR,
  // - Refunds
  DOCTOR_PAYMENTS_REFUNDS_SUCCESS,
  DOCTOR_PAYMENTS_REFUNDS_ERROR,
  // - Payouts
  DOCTOR_PAYMENTS_PAYOUTS_SUCCESS,
  DOCTOR_PAYMENTS_PAYOUTS_ERROR,
  // Tabs
  DOCTOR_PAYMENTS_TAB,
  DOCTOR_PATIENTS_TAB,
  DOCTOR_APPOINTMENTS_TAB
}
