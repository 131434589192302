import { PATIENT_TYPES } from '../../types/patient'
import { AUTH_TYPES } from '../../types/auth'

const initialState = {
  cards: {
    data: null,
    error: null
  },
  billing: {
    data: null,
    error: null
  },
  invoices: {
    data: null,
    error: null
  },
  appointments: {
    data: null,
    error: null
  },
  subscriptions: {
    data: null,
    error: null
  },
  isAppointmentsLoading: false,
  isSubscriptionsLoading: false,
  isLoadingCards: false,
  isLoadingBilling: false,
  isLoadingInvoices: false,
  appointmentsTrigger: false,
  stripeApiKey: null
}

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    // Loader
    case PATIENT_TYPES.PATIENT_CARDS_IS_LOADING:
      return { ...state, isLoadingCards: true }
    case PATIENT_TYPES.PATIENT_BILLING_IS_LOADING:
      return { ...state, isLoadingBilling: true }
    case PATIENT_TYPES.PATIENT_INVOICES_IS_LOADING:
      return { ...state, isLoadingInvoices: true }
    case PATIENT_TYPES.PATIENT_APPOINTMENTS_IS_LOADING:
      return { ...state, isAppointmentsLoading: true }
    case PATIENT_TYPES.PATIENT_SUBSCRIPTIONS_IS_LOADING:
      return { ...state, isSubscriptionsLoading: true }

    case PATIENT_TYPES.PATIENT_APPOINTMENTS_TRIGGER:
      return { ...state, appointmentsTrigger: !state.appointmentsTrigger }

    // Cards
    case PATIENT_TYPES.PATIENT_CARDS_SUCCESS:
      return {
        ...state,
        isLoadingCards: false,
        cards: { data: action.cards, error: null }
      }
    case PATIENT_TYPES.PATIENT_CARDS_ERROR:
      return {
        ...state,
        isLoadingCards: false,
        cards: { ...state.cards, error: action.error }
      }

    // Cards
    case PATIENT_TYPES.API_KEY_SUCCESS:
      return {
        ...state,
        stripeApiKey: action.apiKey
      }
    case PATIENT_TYPES.API_KEY_ERROR:
      return {
        ...state,
        stripeApiKey: action.error
      }

    // Billing
    case PATIENT_TYPES.PATIENT_BILLING_SUCCESS:
      return {
        ...state,
        isLoadingBilling: false,
        billing: { data: action.billing, error: null }
      }
    case PATIENT_TYPES.PATIENT_BILLING_ERROR:
      return {
        ...state,
        isLoadingBilling: false,
        billing: { ...state.billing, error: action.error }
      }

    // Invoices
    case PATIENT_TYPES.PATIENT_INVOICES_SUCCESS:
      return {
        ...state,
        isLoadingInvoices: false,
        invoices: { data: action.invoices, error: null }
      }
    case PATIENT_TYPES.PATIENT_INVOICES_ERROR:
      return {
        ...state,
        isLoadingInvoices: false,
        invoices: { ...state.invoices, error: action.error }
      }

    // Appointments
    case PATIENT_TYPES.PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        isAppointmentsLoading: false,
        appointments: { data: action.appointments, error: null }
      }
    case PATIENT_TYPES.PATIENT_APPOINTMENTS_ERROR:
      return {
        ...state,
        isAppointmentsLoading: false,
        appointments: { ...state.appointments, error: action.error }
      }

    // Subscriptions
    case PATIENT_TYPES.PATIENT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isSubscriptionsLoading: false,
        subscriptions: { data: action.subscriptions, error: null }
      }
    case PATIENT_TYPES.PATIENT_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        isSubscriptionsLoading: false,
        subscriptions: { ...state.subscriptions, error: action.error }
      }

    case AUTH_TYPES.LOGOUT:
      return initialState

    default:
      return state
  }
}

export default patientReducer
